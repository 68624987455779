@import "colors";
@import "responsive";
@media #{$standard} {
  header {min-height: 70px}
}
main {
  @media #{$standard} {margin-top: 0}
  div.post {
    clear: both;
    @media #{$standard} {
      .mobile {display: none}
    }
    .no-excerpt {
      display: none;
      @media #{$mobile} {
        &.standard {display: block}
      }
    }
    aside {
      display: block; float: right;
      margin: {top: 2px; right: 8px;}
      padding: 4px 8px;
      border-top: {left-radius: 8px; right-radius: 8px;}
      box-shadow: 2px 2px 6px $iron;
      background: {
        image: radial-gradient(at 10px 10px, white, $clouds);
      }
      text-align: right; color: $greend;
      font: {style: italic; weight: 700;}

      @media #{$standard} {
        display: block; float: left;
        background: none;
        border-radius: 0;
        box-shadow: none;
        margin: 32px 0 0 0;
      }

      time {font-size: 11pt}
      a.tw {
        width: 24px;
        height: 24px;
        margin-left: 4px;
        vertical-align: text-bottom;
        background-image: url(/img/tw-white.png);
      }
      img.snippet {
        display: none;
        @media #{$standard} {
          display: block;
          max-width: 120px;
          margin: 4px auto;
        }
      }
    }
    article {
      display: block; clear: both;
      position: relative; z-index: 1;
      margin: 8px;
      background-image: radial-gradient(at 10px 10px, white, $clouds);
      box-shadow: 2px 2px 6px $iron;
      border-radius: 8px 0 8px 8px;
      padding-bottom: 4px;
      @media #{$standard} {
        clear: none;
        background: none;
        box-shadow: none;
        margin-top: 0;
        margin-left: 128px;
      }

      a.title {
        display: block;
        margin: 0 -6px; padding: 4px 8px;
        border-radius: 4px;
        padding: 8px;
        text: {decoration: none; transform: uppercase;}
        font: {size: 12pt; weight: 700;}
        color: white;
        background-image: radial-gradient(at 10px 10px, $greenl, $greend);
        &:hover {
          background-image: radial-gradient(at 10px 10px, $sun, $orange);
        }
        @media #{$standard} {
          text-align: right;
          border-radius: 8px;
          padding: 4px 8px;
        }
      }
      section {
        overflow: auto;
        padding: 0 8px;
        font-size: 12pt; color: $midnight;
        & * {font-size: 12pt}
        .global {margin-bottom: 24px}
        h1, p {margin: 8px}
        h1:nth-of-type(n+2) {margin-top: 24px}
        h1, p, li {
          hyphens: auto; -webkit-hyphens: auto; -moz-hyphens: auto; -ms-hyphens: auto;
        }
        p, li {text-align: justify}
        img {
          display: block;
          box-shadow: 0 0 2px $silver;
          border-radius: 4px;
          max-width: 240px; max-height: none;
          width: 100%; height: auto;
          margin: 8px auto;
          &.snippet {float: none}
          @media #{$standard} {
            margin: 8px;
            width: auto;
            max-height: 160px;
            &.snippet {float: right}
          }
        }
      }
    }
  }
}
